import '@fortawesome/fontawesome-free/css/all.min.css';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';
import 'zurb-twentytwenty/css/twentytwenty.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../css/app.scss';

import $ from 'jquery';
global.$ = global.jQuery = $;
import 'bootstrap';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min';
import 'zurb-twentytwenty/js/jquery.event.move';
import 'zurb-twentytwenty/js/jquery.twentytwenty';
import inView from 'in-view';
import 'slick-carousel';
import Vivus from 'vivus';
global.Vivus = Vivus;
// import de la librairie fullpage.js sous le nom "fullpage"
import 'fullpage.js/dist/fullpage';
import fullpage from 'fullpage.js/dist/fullpage.extensions.min';

require(['lightgallery'], function() {
  require(["lg-zoom", "lg-share", "lg-fullscreen"], function(){
    $(document).ready(function() {
      $(".gallery").lightGallery({
        subHtmlSelectorRelative: true
      });
    });
  });
});

(function(document, window, $) {

  const $body = $('body');
  const $navbar = $('#navbar');
  const $btnToggleCatNav = $('.toggle-side-nav');
  const $sideNavItem = $('.side-nav-item');
  const $designCarrousel = $('#design-carrousel');
  const $designSlider = $('#design-slider');
  const $architectureCarrousel = $('#architecture-carrousel');
  const $architectureSlider = $('#architecture-slider');
  const $identityCarrousel = $('#identity-carrousel');
  const $identitySlider = $('#identity-slider');
  const $compare = $('.compare');
  const $scrollableContent = $('.scrollable-content');
  const $swipeNav = $('#swipe-nav');
  const $swipeContentTop = $('#swipe-content-top');
  const $swipeContentBot = $('#swipe-content-bottom');
  const $homeMore = $('#home-more');
  const $homeAnchor = $('.home-anchor');
  const $graph = $('#graph');
  const $nograph = $('#nograph');
  const slidesToShowLg = 3;
  let xDown = null;
  let yDown = null;

  setTimeout(() => {
    $body.removeClass('preload');
  }, 100);

  var deleteLog = false;

  // création d'un nouvel opérateur grâce à la libraire importée plus haut.
  new fullpage('#fullpage', {
    /**
     * options :
     * https://github.com/alvarotrigo/fullPage.js#options
     *
     */
    licenseKey: '742F4970-26084ADD-A325DA15-2DDE0E41',
		scrollingSpeed: 750,
		autoScrolling: true,
    slidesNavigation: true,
    offsetSections: true,

    // à garder? permet de créer une boucle dans le scroll,
    // loopTop: true, // si en début de page on scroll vers le haut ça amène en bas de page,
    // loopBottom: true, // et inversement.

    responsiveWidth: 991.98,

    // parallax: true,
		// parallaxKey: 'YWx2YXJvdHJpZ28uY29tXzlNZGNHRnlZV3hzWVhnPTFyRQ==',
		// parallaxOptions: {
    //   type: 'cover',
		// 	percentage: 60,
		// 	property: 'translate'
		// },

    /**
     * fonction qui s'active après le chargement de la section
     * affiche le btn "découvrir" avec un slide up.
     */
    onLeave: function(origin, destination, direction) {
      const $itemOrigin = $(origin.item);
      const $projectLinkOrigin = $itemOrigin.find('.project-link');
      // console.log('onLeave', $projectLinkOrigin);
      $projectLinkOrigin.hide('slow');

      const $itemDestination = $(destination.item);
      const $projectLinkDestination = $itemDestination.find('.project-link');
      // console.log('onLeave', $projectLinkDestination);
      $projectLinkDestination.delay(350).show('slow');
    },
  });

  $homeMore.on('click', (e) => {
    // window.scrollTo({
    //   top: (window.innerHeight - 61),
    //   left: 0,
    //   behavior: 'smooth'
    // })
    fullpage_api.moveSectionDown();
    // fullpage_api.moveTo(2);
  });

  $homeAnchor.on('click', (e) => {
    fullpage_api.moveTo(1);
  });

  $sideNavItem.on('click', (e) => {
    $(e.currentTarget).addClass('hover');
  }).on('mouseover', (e) => {
    const $this = $(e.currentTarget);
    if (!$this.hasClass('active')) {
      $this.siblings().each((i,el) => {
        const $el = $(el);
        if ($el.hasClass('active')) {
          $el.addClass('hide');
        }
      })
    }
  }).on('mouseout', (e) => {
    $('.side-nav-item.active').removeClass('hide')
  });

  const toggleCatNav = () => {
    // Si (lg && !detail)
    if ((window.matchMedia("(min-width: 992px)").matches && !$body.hasClass('permanent-side-nav')) ) {
      $btnToggleCatNav.parent().toggleClass('show');
      $navbar.collapse('hide');
      // $body.toggleClass('show-cat-nav');
      $body.toggleClass('show-project-nav');
      $('.dropdown-menu').dropdown('hide');
      return false;
    }
  };

  $navbar.on('show.bs.collapse', function() {
    const height = $(window).height() - 61;
    if ($body.hasClass('transparent-nav-bar')) {
      $body.addClass('force-bg-nav-bar');
    }
    $navbar.addClass('transition-opacity');
    setTimeout(() => {
      $navbar.css({"height": height + 'px'})
    }, 50);
  });

  $navbar.on('hide.bs.collapse', function() {
    $navbar.removeClass('transition-opacity');
  });

  $navbar.on('hidden.bs.collapse', function() {
    if ($body.hasClass('transparent-nav-bar')) {
      $body.removeClass('force-bg-nav-bar');
    }
  });

  $btnToggleCatNav.on('click', toggleCatNav);

  $('.nav-link.dropdown-toggle:not(.toggle-side-nav)').on('click', function() {
    if ((window.matchMedia("(min-width: 992px)").matches)) {
      $btnToggleCatNav.parent().removeClass('show');
      $body.removeClass('show-project-nav show-cat-nav');
    }
  })

  $('.dropdown-toggle').on('click', () => {
    if ((window.matchMedia("(min-width: 992px)").matches)) {
      return false;
    }
  });

  $('.dropdown').on('show.bs.dropdown', function() {
    $(this).find('.dropdown-menu').first().stop(true, true).slideDown();
  });

  $('.dropdown').on('hide.bs.dropdown', function() {
    $(this).find('.dropdown-menu').first().stop(true, true).slideUp();
  });

  $('.btn-project-detail').on('click', function() {
    $(this).siblings('.dropdown-menu').slideToggle();
  })

  $('.nav-item.dropdown').on('mouseenter', function() {
    if ((window.matchMedia("(min-width: 992px)").matches)) {
      const $this = $(this);
      $this.find('.dropdown-menu:not(.d-lg-none)').dropdown('show');
      $this.find('.dropdown-item a').on('click', function() {
        window.location = this.href;
      });
    }
  }).on('mouseleave', function() {
    if ((window.matchMedia("(min-width: 992px)").matches)) {
      const $this = $(this);
      $this.find('.dropdown-menu:not(.d-lg-none)').dropdown('hide');
    }
  });

  /**
   * Function qui repère un scroll top et un scroll down,
   * qui nous permet d'afficher ou de cacher le sous-menu projet
   * lorsque l'on est sur mobile.
   */
  let position = $(window).scrollTop();

  if (window.matchMedia("(max-width: 992px)").matches) {
    let lastScrollTop = 0;
    window.addEventListener("scroll", function(){
      let st = window.pageYOffset || document.documentElement.scrollTop;
      if(st > 61) {
        $('#nav-cat-detail-mobile').addClass('fixed-nav');
        if (st > lastScrollTop){
          $('#nav-cat-detail-mobile').addClass('hide-nav');
          $('#dropdown-submenu-detail-mobile').slideUp();
        } else {
          $('#nav-cat-detail-mobile').removeClass('hide-nav');
        }
      } else {
        $('#nav-cat-detail-mobile').removeClass('fixed-nav');
      }
      lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    }, false);
  }

  $navbar.on('show.bs.collapse', () => {
    if (!$body.hasClass('permanent-side-nav')) {
      $btnToggleCatNav.parent().removeClass('show');
      // $body.removeClass('show-cat-nav');
      $body.removeClass('show-project-nav');
    }
  });

  $(document).click((event) => {
    // Si (lg && !detail)
    if ((window.matchMedia("(min-width: 992px)").matches && !$body.hasClass('permanent-side-nav'))) {
      const $target = $(event.target);
      if(!$target.closest('.side-nav').length && $('.side-nav').is(":visible")) {
        $btnToggleCatNav.parent().removeClass('show');
        $body.removeClass('show-project-nav show-cat-nav');
      }
    }
  });

  $compare.twentytwenty({
    before_label: 'Avant',
    after_label: 'Après',
  });

  if ((window.matchMedia("(min-width: 992px)").matches)) {
    $scrollableContent.mCustomScrollbar({
      theme: 'dark-2',
      autoHideScrollbar: true,
      alwaysShowScrollbar: 0,
      autoExpandScrollbar: false,
    });
  }

  inView('.img-grid-container').on('enter', el => {
    $(el).addClass('in-view');
  });

  // utilisation de la libraire InView (importée plus haut) pour ajouter et retirer des classes au menu en fonction de où l'on se trouve sur le site.
  inView.offset({
    top: 100,
    bottom: 100,
  });
  inView('.block-in-view h1').on('enter', el => {
    const $navEl = $('.dropdown-item.' + el.id);

    $('.dropdown-menu .dropdown-item').removeClass('nav-in-view');

    $navEl.addClass('nav-in-view');
  });
  // Addon à la fonction inView() qui vérifie quel menu est ouvert grâce à la classe .active et lui ajoute la classe .show (classe qui change l'aspect du bouton plus).
  const navBtn = document.getElementsByClassName('nav-item');
  for (let i = 0; i < navBtn.length; i++) {
    const el = navBtn[i];
    if (el.classList.contains('active')) {
      el.classList.add('show');
    }
  }

  $designCarrousel.slick({
    infinite: true,
    slidesToShow: slidesToShowLg,
    slidesToScroll: 1,
    centerMode: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        }
      },{
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        }
      },{
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
          variableWidth: true,
        }
      }
    ]
  });

  $designSlider.slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    fade: true,
  });

  $designCarrousel.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
    $('.collapse', $designSlider).collapse('hide');
    $designSlider.slick('slickGoTo', nextSlide);
  });

  $architectureCarrousel.slick({
    infinite: true,
    slidesToShow: slidesToShowLg,
    slidesToScroll: 1,
    centerMode: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        }
      },{
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        }
      },{
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
          variableWidth: true,
        }
      }
    ]
  });

  $architectureSlider.slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    fade: true,
  });

  $architectureCarrousel.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
    $('.collapse', $architectureSlider).collapse('hide');
    $architectureSlider.slick('slickGoTo', nextSlide);
  });

  $identityCarrousel.slick({
    infinite: true,
    slidesToShow: slidesToShowLg,
    slidesToScroll: 1,
    centerMode: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        }
      },{
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        }
      },{
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
          variableWidth: true,
        }
      }
    ]
  });

  $identitySlider.slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    fade: true,
  });

  $identityCarrousel.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
    $('.collapse', $identitySlider).collapse('hide');
    $identitySlider.slick('slickGoTo', nextSlide);
  });

  const handleTouch = (direction) => {
    if ((window.matchMedia("(max-width: 991px)").matches)) {
      const $items = $('a',$swipeNav);
      const $item = $('a.bg-primary',$swipeNav);

      if(direction === 'left' && $item.index() < ($items.length - 1)) {
        const $next = $items.eq($item.index() + 1);
        $body.addClass('swipe-left');
        window.location.href = $next.attr('href');
      }

      if(direction === 'right' && $item.index() > 0) {
        const $prev = $items.eq($item.index() - 1);
        $body.addClass('swipe-right');
        window.location.href = $prev.attr('href');
      }
    }
  }

  function getTouches(evt) {
    return evt.touches ||        // browser API
      evt.originalEvent.touches; // jQuery
  }

  function handleTouchStart(evt) {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  }

  function handleTouchMove(evt) {
    if ( ! xDown || ! yDown ) {
      return;
    }

    let xUp = evt.touches[0].clientX;
    let yUp = evt.touches[0].clientY;

    let xDiff = xDown - xUp;
    let yDiff = yDown - yUp;

    if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
      if ( xDiff > 0 ) {
        /* left swipe */
        handleTouch('left');
      } else {
        /* right swipe */
        handleTouch('right');
      }
    }
    /* reset values */
    xDown = null;
    yDown = null;
  }

  $swipeContentTop.on('touchstart', handleTouchStart).on('touchmove', handleTouchMove);
  $swipeContentBot.on('touchstart', handleTouchStart).on('touchmove', handleTouchMove);

  // Au clique du btn (à l'id "btn-long-bio"), toggle de l'élement qui à l'id "short-bio"
  $('#btn-long-bio').click(function() {
    $( "#short-bio" ).slideToggle( "slow", function() {
    });
  });

  // création de variable et de condition pour influencer le menu en mode mobile pour qu'il soit ouvert sur la page sur laquellle se trouve l'internaute.
  if (window.matchMedia("(max-width: 992px)").matches) {

    let pageTitle = document.querySelector('h1');
    let projectSubtitle = document.getElementById('project-type-title');

    const itemMenu = document.querySelectorAll('.nav-item .dropdown-menu');
    const itemBold = document.querySelectorAll('.side-nav-link');

    if(pageTitle != null) {
      // console.log('je suis dans le if');
      let pageTitleSelect = pageTitle.innerHTML;

      switch (pageTitleSelect) {
        case 'Services':
          itemMenu[1].classList.add('show');
          break;
        case 'Mission':
          itemMenu[2].classList.add('show');
          break;
        case 'Profil':
          itemMenu[3].classList.add('show');
          break;
        case 'Studio':
          itemMenu[4].classList.add('show');
          break;
        default:
          console.log('pas de show par défaut');
      }
    } else {
      // console.log('je suis dans le else');
      // pageTitleSelect = undefined;
    }

    if(projectSubtitle = 'String' || projectSubtitle != null) {
      // console.log('je suis dans le if');
      let projectSubtitleSelect = projectSubtitle.innerHTML;

      switch (projectSubtitleSelect) {
        case 'Horlogerie':
          itemMenu[0].classList.add('show');
          itemBold[0].style.fontWeight = "700";
          break;
        case 'Joaillerie':
          itemMenu[0].classList.add('show');
          itemBold[1].style.fontWeight = "700";
          break;
        case 'Maroquinerie':
          itemMenu[0].classList.add('show');
          itemBold[2].style.fontWeight = "700";
          break;
        case 'Identité':
          itemMenu[0].classList.add('show');
          itemBold[3].style.fontWeight = "700";
          break;
        case 'Objets':
          itemMenu[0].classList.add('show');
          itemBold[4].style.fontWeight = "700";
          break;
        case 'Architecture':
          itemMenu[0].classList.add('show');
          itemBold[5].style.fontWeight = "700";
          break;
        default:
          // console.log('pas de show et de bold par défaut');
      }
    } else {
      // console.log('je suis dans le else');
      // projectSubtitleSelect = undefined;
    }
  }

  $graph.on('click', '.graph-content', function () {
    const $this = $(this);
    const $collapse = $('#'+$this.data('content'));
    const $graphBlockText = $('.graph-block-text');

    $('.graph-picto', $graph).removeClass('active');
    $('.graph-picto', $this).addClass('active');

    if($graphBlockText.hasClass('graph-active')) {
      $('.graph-collapse').collapse('hide');
      $graphBlockText.removeClass('graph-active');
      setTimeout(() => {
        $('.graph-block', $graph).removeClass('graph-active');
      }, 150);
      setTimeout(() => {
        $this.parent().addClass('graph-active');
      }, 300);
      setTimeout(() => {
        $('.graph-block-text', $graph).addClass('graph-active');
      }, 450);
      setTimeout(() => {
        $collapse.collapse('show');
      }, 600);
    } else {
      $this.parent().addClass('graph-active');
      setTimeout(() => {
        $('.graph-block-text', $graph).addClass('graph-active');
      }, 150);
      setTimeout(() => {
        $collapse.collapse('show');
      }, 300);
    }
  })



  $nograph.on('click', '.graph-content', function () {
    const $this = $(this);
    const $collapse = $('#'+$this.data('content'));

    $('.nograph-collapse').collapse('hide');
    $('.graph-picto', $nograph).removeClass('active');
    $('.graph-picto', $this).addClass('active');

    $('.graph-block', $nograph).removeClass('graph-active');
    $this.parents('.graph-block').addClass('graph-active');
    setTimeout(() => {
      $collapse.collapse('show');
    }, 150);
  })

})(document, window, $);
